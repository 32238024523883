<script>
export default {
  name: 'ViewWallet',

  data() {
    return {
      searchSelected: false,
      searchValue: '',
      loading: true,
      wallet: null,
      searchSelected: false,
      searchValue: '',
      selectedBrand: null,
      sortField: 'name',
      selectedBrands: [],
      nfts: [],
    }
  },

  watch: {
    searchValue(value) {
      if(value) {
        window.sessionStorage.setItem('wallet-filters-search', value)
        return
      }

      window.sessionStorage.removeItem('wallet-filters-search')
    },

    sortField(value) {
      if(value) {
        window.sessionStorage.setItem('wallet-filters-field', value)
        return
      }

      window.sessionStorage.removeItem('wallet-filters-field')
    },

    selectedBrand(value) {
      if(value) {
        window.sessionStorage.setItem('wallet-filters-brand', value)
        return
      }

      window.sessionStorage.removeItem('wallet-filters-brand')
    }
  },

  computed: {
    brands() {
      return [
        {
          label: this.$t('conn3ct-wallet.brands_filter_placeholder'),
          value: null,
          active: !this.selectedBrand,
        },
        ...this.brandsList.map(b => {
          return {
            label: b.name,
            value: b.id,
            active: b.id === this.selectedBrand
          }
        })
      ]
    },

    brandsList() {
      return this.$brands.brands?.list ?? []
    },

    filteredNfts() {
      return this.nfts
        .filter(n => n.name.toUpperCase().includes(this.searchValue.toUpperCase()))
        .filter(n => !this.selectedBrand || n.saylAppBrand?.id === this.selectedBrand)
        .sort((nftA, nftB) => {
          let valueA, valueB = null
          let modifier = 1

          if(this.sortField === 'name') {
            valueA = nftA.name.toUpperCase()
            valueB = nftB.name.toUpperCase()
          }

          if(this.sortField === 'date') {
            modifier = -1
            valueA = nftA.saylNft?.modified
            valueB = nftB.saylNft?.modified
          }

          if(valueA > valueB) {
            return modifier * 1
          }

          if(valueA < valueB) {
            return modifier * -1
          }

          return 0
        })
        .filter(n => this.selectedBrands.length === 0 || this.selectedBrands.includes(n.saylAppBrand?.id))
    },

    hasNfts() {
      return this.nfts.length
    },

    isBrandedContext() {
      return !!sessionStorage.getItem('saylAppBrandId')
    },

    isSearchOpened() {
      return this.searchSelected || this.searchValue.length > 0
    },

    pageTitle() {
      if(!this.$wallet.selectedWallet || this.loading) {
        return this.$t('conn3ct-wallet.sayl_wallet_name')
      }

      const { name } = this.$externalWallet.wallets.find(w => w.id = this.$wallet.selectedWallet)

      return name
    },

    sortOptions() {
      return [
        {
          label: this.$t('conn3ct-wallet.nft_sort_by_name'),
          value: 'name',
          active: this.sortField === 'name',
        },

        {
          label: this.$t('conn3ct-wallet.nft_sort_by_date'),
          value: 'date',
          active: this.sortField === 'date',
        }
      ]
    },
  },

  methods: {
    brandLogo(logo) {
      return logo?.url?.all
    },

    checkSelectedBrand(id) {
      return this.selectedBrands.includes(id)
    },

    closeSearch() {
      this.searchSelected = false
    },

    async init(resetCache = false) {
      this.loading = true

      try {
        await this.$brands.getBrands()
        this.wallet = await this.$wallet.getWallet(resetCache, this.$route.params.id)

        if(this.$route.params.id === 'sayl') {
          this.loadUnconfirmed()
        }

        this.nfts = this.wallet?.nfts ?? []

        this.nfts.forEach(n => {
          n.saylAppBrand = this.$brands.brands.list.find(b => b.id === n.saylAppBrand?.id)
        })
      } catch(e) {
        $console.error(e)
      } finally {
        this.loading = false
      }
    },

    initFilters() {
      const search = window.sessionStorage.getItem('wallet-filters-search')
      const field = window.sessionStorage.getItem('wallet-filters-field')
      const brand = window.sessionStorage.getItem('wallet-filters-brand')

      if(search) {
        this.searchValue = search
      }

      if(field) {
        this.selectedBrand = field
      }

      if(brand) {
        this.selectedBrand = brand
      } else {
        this.selectedBrand = null
      }
    },

    async loadUnconfirmed() {
      try {
        await this.$wallet.getUnconfirmed()

        this.$wallet.unconfirmed.forEach(n => {
          n.nft.saylAppBrand = this.$brands.brands.list.find(b => b.id === n.nft.saylAppBrand?.id)
        })

        setTimeout(() => {
          if(this.$wallet.unconfirmed?.length) {
            this.hadUnconfirmedNfts = true

            if(this.$route.name === 'sayl-connect_wallet-wallet') {
              this.loadUnconfirmed()
            }

            return
          }

          if(this.hadUnconfirmedNfts) {
            this.hadUnconfirmedNfts = false
            this.init(true)
          }
        }, 5000)
      } catch(e) {
        $console.error(e)
      }
    },

    openSearch() {
      this.searchSelected = true
    },

    resetFilters() {
      this.selectedBrand = null
      this.selectedBrands = []
      this.searchValue = ''
    },

    selectBrand(id) {
      const isSelected = this.checkSelectedBrand(id)

      if(!isSelected) {
        this.selectedBrands = [...this.selectedBrands, id]
        return
      }

      this.selectedBrands = this.selectedBrands.filter(b => b !== id)
    }
  },

  created() {
    let { id } = this.$route.params

    if(id === 'sayl') id = null

    this.$wallet.setWallet(id)
  },

  created() {
    this.nfts = this.$wallet.wallets[this.$route.params.id]?.nfts ?? []
  },

  mounted() {
    window.scrollTo(0, 0)
    this.init(true)
    this.initFilters()
  }
}
</script>

<template>
  <layout-page
    :back="{ name: 'sayl-connect_dashboard-dashboard' }"
    class="module-wallet wallet"
    :title="pageTitle"
    :subtitle="$t('conn3ct-wallet.collection_description')"
    :loading="loading && !hasNfts"
  >
    <section
      v-if="!isBrandedContext && this.brandsList.length"
      class="wallet__brands"
    >
      <h2 class="wallet__brands-title">{{ $t('conn3ct-wallet.wallet_brands') }}</h2>

      <div class="wallet__brands-list">
        <button
          v-for="brand in brandsList"
          @click="selectBrand(brand.id)"
          class="wallet__brands-item"
          :class="{ '-is-active': checkSelectedBrand(brand.id) }"
          :key="brand.id"
        >
          <ui-illustration
            v-if="brandLogo(brand.logo)"
            :src="brandLogo(brand.logo)"
          />

          <span>{{ brand.name }}</span>
        </button>
      </div>
    </section>

    <section class="wallet__content">
      <div class="wallet__content-header">
        <h2 class="wallet__content-title">
          {{ $t('conn3ct-wallet.wallet_content_title') }}

          <actions-button
            v-if="$route.params.id === 'sayl'"
            @click="$router.push({ name: 'sayl-connect_wallet-transfers' })"
            class="wallet__open-transfers"
          >
            <icon-transfer />
            <!-- <span class="wallet__open-transfers-label">{{ $t('conn3ct-wallet.wallet_show_transfers') }}</span> -->
          </actions-button>
        </h2>

        <div
          class="wallet__filters"
          :class="{ '-search-opened': isSearchOpened }"
        >
          <forms-input
            @focus="openSearch"
            @blur="closeSearch"
            v-model="searchValue"
            :appearance="$pepper.Appearance.SUBTLE"
            class="filters__name"
            :placeholder="$t('conn3ct-wallet.wallet_search_placeholder')"
          >
            <template v-slot:prefix>
              <icon-search @click="openSearch" />
            </template>
          </forms-input>

          <forms-select
            v-model="sortField"
            class="filters__order-type"
            :options="sortOptions"
          >
            <template v-slot:prefix>
              <div class="filters__select-prefix sort-type">
                <icon-arrow orientation="bottom" />
              </div>
            </template>
          </forms-select>

          <forms-select
            v-if="!isBrandedContext && !selectedBrands.length"
            v-model="selectedBrand"
            class="filters__brands"
            :options="brands"
          >
            <template v-slot:prefix>
              <icon-handbag class="filters__select-prefix" />
            </template>
          </forms-select>
        </div>
      </div>

      <layout-entity-list
        @emptyAction="resetFilters"
        class="wallet__content-list"
        :empty="!filteredNfts.length && !$wallet.unconfirmed.length"
        :empty-action-label="$t('conn3ct-wallet.reset_filters')"
        :empty-description="$t('conn3ct-wallet.no_nfts_result_description')"
        :empty-title="$t('conn3ct-wallet.no_nfts_result_title')"
      >
        <template v-if="$route.params.id === 'sayl'">
          <ui-card
            v-for="nft in $wallet.unconfirmed"
            class="wallet__transfer"
            :key="nft.id"
            :title="nft.nft.name"
          >
            <template v-slot:illustration>
              <ui-illustration
                :alt="nft.nft.name"
                fit="contain"
                :src="nft.nft.imageUrl"
                :type="nft.nft.imageType"
              />
            </template>

            <template
              v-if="$basil.get(nft, 'nft.saylAppBrand.name')"
              v-slot:subtitle
            >
              <div class="wallet__nft-brand">
                <ui-illustration
                  v-if="$basil.get(nft, 'nft.saylAppBrand.logo')"
                  :alt="$basil.get(nft, 'nft.saylAppBrand.name')"
                  class="wallet__nft-brand-logo"
                  :src="brandLogo(nft.nft.saylAppBrand.logo)"
                />

                <span class="ui-card__subtitle">{{ $basil.get(nft, 'nft.saylAppBrand.name') }}</span>
              </div>
            </template>

            <template v-slot:footer>
              <div class="row">
                <button class="wallet__nft-cta border-animation">
                  {{ $t('conn3ct-wallet.in_transfer') }}
                </button>
              </div>
            </template>
          </ui-card>
        </template>

        <ui-card
          v-for="nft in filteredNfts"
          class="wallet__nft"
          :key="nft.id"
          :subtitle="$basil.get(nft, 'saylAppBrand.name')"
          :title="nft.name"
          :to="{ name: 'sayl-connect_wallet-nft', params: { id: nft.id } }"
        >
          <template v-slot:illustration>
            <ui-illustration
              :alt="nft.name"
              fit="contain"
              :src="nft.imageUrl"
              :type="nft.imageType"
            />

            <div class="wallet__nft-overlay">
              <span
                v-if="nft.vouchers.length"
                class="wallet__nft-tag"
              >{{ $t('conn3ct-wallet.vouchers_count', { vouchersCount: nft.vouchers.length }) }}</span>

              <span
                v-if="nft.web3Triggers.length || nft.web3Vouchers.length"
                class="wallet__nft-tag"
              >{{ $t('conn3ct-wallet.perks_count', { perksCount: nft.web3Triggers.length + nft.web3Vouchers.length }) }}</span>
            </div>
          </template>

          <template
            v-if="$basil.get(nft, 'saylAppBrand.name')"
            v-slot:subtitle
          >
            <div class="wallet__nft-brand">
              <ui-illustration
                v-if="$basil.get(nft, 'saylAppBrand.logo')"
                :alt="$basil.get(nft, 'saylAppBrand.name')"
                class="wallet__nft-brand-logo"
                :src="brandLogo(nft.saylAppBrand.logo)"
              />

              <span class="ui-card__subtitle">{{ $basil.get(nft, 'saylAppBrand.name') }}</span>
            </div>
          </template>

          <template v-slot:footer>
            <div class="row">
              <div class="wallet__nft-chain">
                <img
                  :alt="`${nft.chain.value} logo`"
                  :src="$root.getSrc(`statics/images/${nft.chain.value}.png`)"
                />
              </div>

              <button class="wallet__nft-cta">
                {{ $t('conn3ct-wallet.view') }}
              </button>
            </div>
          </template>
        </ui-card>
      </layout-entity-list>
    </section>
  </layout-page>
</template>
